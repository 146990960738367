import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Container = () => (
  <Layout>
    <SEO title="Home" />
    <div className="home">
      <header
        className="color-bg light-typo padding-top-bottom bg-image detail-header"
        style={{
          backgroundImage: `url(/images/etc/reservation_header.jpg)`,
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <h1 className="page-title">예약하기</h1>

          <p className="page-subtitle">
            원하는 날짜의 객실을 선택하면 실시간 예약화면으로 이동합니다
          </p>
        </div>
      </header>

      <article className="post single-post">
        <div className="container fade-up">
          <div className="row">
            <div className="col-sm-10 col-sm-offset-1">
              <div className="post-content">
                <div
                  style={{ margin: "0px", padding: "0px", overflow: "hidden" }}
                >
                  <iframe
                    src="https://rev.yapen.co.kr/external?ypIdx=20501"
                    style={{
                      border: "none",
                      width: "100%",
                      height: "1300px",
                    }}
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>
  </Layout>
)

export default Container
